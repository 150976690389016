<template>
  <v-card flat class="px-5 pb-5">
    <v-card-title class="pb-8">Исправление liner МК</v-card-title>
    <file-input v-model="files" accept=".pdf" id="liner" />
    <v-row justify="space-between" class="mt-4">
      <v-col cols="12" md="4" align-self="center">
        <div v-if="!files.length" class="text-h5">
          <v-icon class="mr-3 text-h4">mdi-information</v-icon>
          Загрузите
          PDF-документы
        </div>
      </v-col>
      <v-col cols="12" md="4" style="display: flex; justify-content: flex-end">
        <v-btn @click="send" :loading="loading" color="orange" :disabled="isDisable" style="width: 100%; height: 3.3rem">
          Получить
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>


<script>
import FileInput from "@/components/UI/FileInput.vue";
import axios from "axios";
import { downloadFile } from "@/mixins/utils";

export default {
  name: "LinerPdfEditor",
  components: { FileInput },
  data() {
    return {
      files: [],
      loading: false,
      test: [],
    };
  },
  methods: {
    async send() {
      this.loading = true;
      try {
        const formData = new FormData();
        formData.append("pdf", this.files[0]);

        let resp = await axios.post(
          this.$enums.Endpoints.Other.Receipt.EditLiner,
          formData,
          { responseType: "blob" }
        );
        downloadFile(resp.data, { name: `liner.pdf` })

      } catch (e) {
        console.error("Error while sending file:", e);
      } finally {
        this.loading = false;
      }
    }
  },
  computed: {
    isDisable() {
      return !(this.files ?? []).length;
    },
  },
};

</script>


<style scoped></style>