<template>
  <v-card flat class="px-5 pb-5">
    <v-card-title class="pb-8">Формирование заявки на размещение</v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12" md="4" class="text-color">
            <custom-date-picker outlined v-model="value.arrivalDate" text="Дата заезда" format="dd.MM.yyyy"
                                :rules="[checkArrivalDate]"/>
          </v-col>
          <v-col cols="12" md="4" class="text-color">
            <v-text-field outlined label="Время заезда" :value="arrivalTimeNormalized"
                          @input="updateArrivalTime" maxlength="5" style="height: 3.5rem" hint="ЧЧ:ММ"
                          :rules="[checkTime || 'Введите время в формате ЧЧ:ММ']" persistent-hint></v-text-field>
          </v-col>
          <v-col cols="12" md="4" class="text-color">
            <v-select outlined label="Кол-во часов" :items="roomTypeHours"
                      v-model="value.selectedRoomTypeHour" style="height: 3.5rem" return-object></v-select>
          </v-col>
        </v-row>
        <v-row style="padding-top: 10px;">
          <v-col cols="12" md="3" class="text-color">
            <v-select outlined label="Тип номера" :items="roomTypes" v-model="value.selectedRoomType"
                      style="height: 3.5rem" return-object></v-select>
          </v-col>
          <v-col cols="12" md="3" class="text-color">
            <v-select outlined label="Кол-во персон" :items="numberOfPeoples"
                      v-model="value.selectedNumberOfPeople" style="height: 3.5rem" return-object></v-select>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field outlined label="Кол-во номеров" v-model="value.roomNumbers" style="height: 3.5rem"
                          :rules="[(val) => val > 0 || 'Введите число, например 1']">
            </v-text-field>
          </v-col>
          <v-col cols="12" md="3" class="text-color">
            <v-select outlined label="Тип оплаты" :items="paymentForms" v-model="value.selectedPaymentForm"
                      style="height: 3.5rem" return-object></v-select>
          </v-col>
          <v-col v-for="(phone, index) of value.phoneNumbers" :key="'phone' + index" cols="12" md="4"
                 class="text-color">
            <v-text-field v-model="phone.phone" outlined label="Номер телефона"
                          :rules="[(val) => val > 0 || 'Введите номер, например +123456789']" style="height: 3.5rem">
            </v-text-field>
          </v-col>
          <v-col v-for="(passanger, index) of value.passangers" :key="index" cols="12" md="4"
                 class="text-color">
            <v-text-field v-if="canPassangerRemoved" v-model="passanger.passanger"
                          append-icon="mdi-close-circle" outlined :label="'ФИО клиента ' + (index + 1)"
                          style="height: 3.5rem" @click:append="removePassanger(index)">
            </v-text-field>
            <v-text-field v-else v-model="passanger.passanger" outlined label="ФИО клиента"
                          style="height: 3.5rem">
            </v-text-field>
          </v-col>
          <v-col cols="12" md="4" class="text-center">
            <v-btn v-if="canPassangerAdded" @click="addNewPassanger" hidden="false"
                   style="width: 100%; height: 3.5rem;">
              <v-icon hidden="false">mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row style="padding-top: 10px;">
          <v-col cols="12" md="6" class="text-color">
            <v-text-field v-model="value.agentName" outlined label="Агент" style="height: 3.5rem">
            </v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-row class="px-4">
        <v-col cols="6" md="3">
          <v-btn block dark color="orange" :disabled="isDownload" style="width: 100%; height: 3.3rem"
                 @click="clear">Очистить форму
          </v-btn>
        </v-col>
        <v-spacer/>
        <v-spacer/>
        <v-col cols="6" md="4" class="text-center">
          <v-btn block dark @click="downloadBlank" :loading="isDownload" color="green"
                 style="width: 100%; height: 3.3rem;" :disabled="isDisabled">
            Сформировать заявку
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import CustomDatePicker from '@/components/UI/CustomDatePicker.vue';
import {downloadFile} from '@/mixins/utils.js';

export default {
  components: {
    CustomDatePicker,
  },
  data() {
    return {
      paymentForms: ['нал', 'б/нал'],
      roomTypes: ['люкс', 'стандарт', 'полу-люкс', 'купе'],
      roomTypeHours: [6, 12, 24],
      numberOfPeoples: [1, 2, 3, 4],
      isDownload: false,
      canPassangerAdd: true,
      value: {
        selectedPaymentForm: "",
        selectedRoomType: "",
        selectedNumberOfPeople: 0,
        selectedRoomTypeHour: 0,
        passangers: [],
        phoneNumbers: [],
        roomNumbers: null,
        arrivalDate: new Date(),
        arrivalTime: "",
        agentName: ''
      },
    }
  },
  methods: {
    updateTime(value, target) {
      if (!value)
        return "";

      if (value.length === 1 && value === '0') {
        return '00';
      }
      if (target.length == 2 && value.length == 2)
        return value.substring(0, 1);


      return value.replace(":", "")

    },
    updateArrivalTime(value) {
      this.value.arrivalTime = this.updateTime(value, this.value.arrivalTime)
    },
    addNewPassanger() {
      this.value.passangers.push({passanger: ''});
    },
    removePassanger(index) {
      this.value.passangers.splice(index, 1);
    },
    addNewPhoneNumber() {
      this.value.phoneNumbers.push({phone: '+7'});
    },
    async downloadBlank() {
      this.value.arrivalDate.setHours(4, 0, 0, 0);
      let dateArrival = this.value.arrivalDate.toISOString();
      let arrivalTimeSubstr = dateArrival.substring(11, 19);
      let dateArrivalDto = dateArrival.replace(arrivalTimeSubstr, this.arrivalTimeNormalized + ":00");
      const blankDto = {
        passangers: this.value.passangers.map(item => item.passanger),
        passangerNumbers: this.value.phoneNumbers.map(item => item.phone),
        arrivalDate: dateArrivalDto,
        roomType: this.value.selectedRoomType,
        numberOfPeople: this.value.selectedNumberOfPeople,
        roomTypeHours: this.value.selectedRoomTypeHour,
        numberOfRooms: this.value.roomNumbers,
        paymentType: this.value.selectedPaymentForm,
        agentName: this.value.agentName
      };
      this.isDownload = true;
      try {
        let resp = await this.$axios.post(this.$enums.Endpoints.Other.CreateHotelBlank, blankDto);
        let uint8Array = new Uint8Array(atob(resp.data).split('').map(function (c) {
          return c.charCodeAt(0);
        }));
        downloadFile(uint8Array, {name: `${blankDto.passangers[0].split(' ')[0]}_atlanta.pdf`})
      } catch (e) {
        console.error(e);
      } finally {
        this.isDownload = false;
      }
    },
    normalizeTime(value) {
      if (value.length > 2)
        return value.substring(0, 2) + ":" + value.substring(2, 4)
      return value;
    },
    clear() {
      this.value = {
        selectedPaymentForm: "",
        selectedRoomType: "",
        selectedNumberOfPeople: 0,
        selectedRoomTypeHour: 0,
        passangers: [],
        phoneNumbers: [],
        roomNumbers: null,
        isDownload: false,
        arrivalDate: new Date(),
        arrivalTime: "",
        agentName: ''
      };

      this.value.passangers.push({passanger: ''});
      this.value.phoneNumbers.push({phone: '+7'});
    },
  },
  computed: {
    isDisabled() {
      return !this.value.selectedPaymentForm
          || !this.value.selectedRoomType
          || !this.value.passangers[0].passanger
          || !this.value.phoneNumbers[0].phone
          || !this.value.selectedRoomTypeHour
          || !this.value.selectedNumberOfPeople
          || !this.value.roomNumbers
          || !this.value.agentName
          || this.value.arrivalTime.length < 4
    },
    arrivalTimeNormalized() {
      return this.normalizeTime(this.value.arrivalTime);
    },
    canPassangerAdded() {
      if (this.value.passangers.length >= 4) {
        return false;
      }
      return true;
    },
    canPassangerRemoved() {
      if (this.value.passangers.length <= 1) {
        return false;
      }
      return true;
    },
    checkArrivalDate() {
      return !(this.value.arrivalDate.getFullYear() < new Date().getFullYear()
          || this.value.arrivalDate.getMonth() < new Date().getMonth()
          || this.value.arrivalDate.getDate() < new Date().getDate())
    },
    checkTime() {
      if (this.value.arrivalTime == "" || this.value.departureTime == "") {
        return false;
      }
      let arrivalTimeFirstDidgits = Number(this.value.arrivalTime.substring(0, 2));
      let arrivalTimeSecondDidgits = Number(this.value.arrivalTime.substring(3, 5));
      if (arrivalTimeFirstDidgits > 23 || arrivalTimeSecondDidgits > 59) {
        return false;
      }


      return true;
    }
  },
  mounted() {
    this.value.passangers.push({passanger: ''});
    this.value.phoneNumbers.push({phone: '+7'});
  }
}
</script>