<template>
  <v-card flat class="px-5 pb-3">
    <v-card-title>Генерация QR</v-card-title>
    <v-carousel v-model="slide" hide-delimiters :show-arrows="false" vertical height="auto">
      <v-carousel-item>
        <v-row justify="center" class="pt-3 pb-3">
          <v-col>
            <v-row justify="space-between">
              <v-col cols="12" class="text-color" align-self="center">
                <v-text-field outlined label="Например: 5300.50" v-model="sum" prefix="₽">
                </v-text-field>
              </v-col>
              <v-col cols="12" md="4" class="text-center">
                <v-btn large dark color="orange" width="90%" @click="routeToQrsPage">
                  Мои продажи
                </v-btn>
              </v-col>
              <v-col v-if="$store.getters.user.role === $enums.Roles.Admin" cols="12" md="4" class="text-center">
                <v-btn large dark color="orange" v-on:click="downloadQr" :loading="isDownload" width="90%">
                  Скачать Отчет
                </v-btn>
              </v-col>
              <v-col cols="12" md="4" class="text-center">
                <v-btn :disabled="isDisable" :loading="isGetting" @click="get" large color="orange" width="90%">
                  Получить QR
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col md="3" v-if="!$vuetify.breakpoint.smAndDown" style="position: relative; top: -30px;">
            <v-col cols="12" class="text-center text-h5">Top-3</v-col>
            <v-row v-for="(item, i) in top" :key="i">
              <v-col class="text-center">{{ item.date }}</v-col>
              <v-col class="text-center">{{ item.cost }} Руб</v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-carousel-item>
      <v-carousel-item>
        <v-row align-content="center" justify="center">
          <v-col cols="12" class="text-color" align-self="center">
            <div class="pl-5 pt-5 text-h4"><v-icon class="mr-3 text-h4" >mdi-information</v-icon> QR-код отправлен на экран.</div>
            <div class="pl-5 py-10 text-h4">
               Здесь QR-код больше отображаться не будет. 
            </div>
            <!-- <iframe id="print" :src="url" frameborder="0" scrolling="auto" class="frame"></iframe> -->
          </v-col>
          <v-col cols="12" class="text-color" align-self="center">
            <v-row justify="space-between" class="px-4">
              <v-col cols="12" md="3">
                <v-btn block dark color="orange" elevation="0" v-on:click="back">Назад</v-btn>
              </v-col>
              <v-col cols="6" md="3">
                <v-btn block dark color="orange" elevation="0" :loading="isGettingStatus"
                  @click="getStatus">Статус</v-btn>
              </v-col>
              <v-col cols="6" md="3">
                <v-btn block dark color="orange" elevation="0" @click="print('print')">Печать</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-carousel-item>
    </v-carousel>
  </v-card>
</template>

<script>
import store from "@/services/store";
import { downloadFile } from '@/mixins/utils.js';

export default {
  data() {
    return {
      qrInfo: null,
      status: "",
      sum: "",
      slide: 0,
      isGetting: false,
      isGettingStatus: false,
      isDownload: false,
      url: "",
      topCount: 3,
      top: [],
      a: []
    };
  },
  async mounted() {
    this.top = await this.getTopSolds(this.topCount);
  },
  methods: {
    statusTranslated() {
      switch (this.status) {
        case "CREATED":
          return "Заказ создан";
        case "PAID":
          return "Заказ оплачен";
        case "REVERSED":
          return "Заказ отменен";
        case "REFUNDED":
          return "Заказ возвращен";
        case "REVOKED":
          return "Заказ отменен";
        default:
          return "Неизвестно";
      }
    },
    async get() {
      this.isGetting = true;
      var req = {
        Amount: Number(this.sum),
      };
      try {
        let resp = await this.$axios.post(
          this.$enums.Endpoints.Payment.QrCreate, req
        );
        this.qrInfo = resp.data;
        this.url = this.qrInfo.url;
        this.slide++;
      } catch (e) {
      } finally {
        this.isGetting = false;
      }
    },
    back() {
      this.slide--;
      this.url = "";
    },
    async getStatus() {
      this.isGettingStatus = true;
      try {
        let resp = await this.$axios.get(
          this.$enums.Endpoints.Payment.QrStatus +
          `?id=${this.qrInfo.serviceId}`
        );
        this.status = resp.data;
        let translatedResponse = this.statusTranslated();
        store.state.snackbar = {
          show: true,
          message: translatedResponse,
          color: "orange",
          timeout: 2000,
          contentClass: "text-center text-h5",
        };
      } catch (e) {
        console.log("Get QR - Error");
      } finally {
        this.isGettingStatus = false;
      }
    },
    async print() {
      window.open(this.url);
    },
    async downloadQr() {
      this.isDownload = true;
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      try {
        let resp = await this.$axios.get(this.$enums.Endpoints.Other.QrReport, {
          responseType: "blob",
        });
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, "0");
        var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        var yyyy = today.getFullYear();
        today = dd + "/" + mm + "/" + yyyy;
        downloadFile(resp.data, { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", name: `Report_${today}.xlsx`, openInNewTab: false })
      } catch (e) {
      } finally {
        this.isDownload = false;
      }
    },
    async getTopSolds(x) {
      var resp = await this.$axios.get(
        this.$enums.Endpoints.Data.TopQrSolds +
        `?id=${this.$store.state.user.id}&count=${x}`
      );
      this.a = resp.data;
      return resp.data.map(e => ({
        cost: e.cost / 100,
        date: e.date.substr(0, 10)
      }));
    },
    async routeToQrsPage() {
      this.$router.push(`/qrs?id=${this.$store.state.user.id}`);
    },
  },
  computed: {
    isDisable() {
      return !(this.sum && isFinite(this.sum));
    },
  },
};
</script>

<style>
.wrap {
  width: 100%;
  height: 400px;
  padding: 0;
  overflow: hidden;
}

.frame {
  height: 800px;
  width: 200%;
  -ms-transform: scale(0.5);
  -moz-transform: scale(0.5);
  -o-transform: scale(0.5);
  -webkit-transform: scale(0.5);
  transform: scale(0.5);

  -ms-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}
</style>
