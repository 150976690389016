<template>
  <v-card flat class="px-5 pb-5">
    <v-card-title class="pb-8">Получение отчёта</v-card-title>
    <v-container>
      <v-row>
        <v-col cols="12" md="4">
          <v-select v-model="selectedCompany" :items="companies" item-text="name" item-value="code"
            label="Выберите компанию"></v-select>
        </v-col>
        <v-col cols="12" md="4" class="text-color">
          <custom-date-picker outlined v-model="startDate" text="Начальная дата" format="dd.MM.yyyy"
            :rules="[checkStartDate]" />
        </v-col>
        <v-col cols="12" md="4" class="text-color">
          <custom-date-picker outlined v-model="endDate" text="Конечная дата" format="dd.MM.yyyy"
            :rules="[checkEndDate]" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4" class="text-center">
          <v-btn @click="downloadReport" :loading="isDownload" color="orange" style="width: 100%; height: 3.3rem">
            Получить отчёт
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import CustomDatePicker from "@/components/UI/CustomDatePicker.vue";
import { downloadFile } from "@/mixins/utils.js";

export default {
  components: {
    CustomDatePicker,
  },
  data() {
    return {
      selectedCompany: null,
      companies: [],
      startDate: null,
      endDate: null,
      isDownload: false,
    };
  },
  mounted() {
    this.getCompanies();
  },
  methods: {
    isS7AdminEmail(email) {
      const emails = ["admin.s7@bpo.travel", "s.mogutova@s7.ru", "e.balatskaya@s7.ru", "t.pidzhakova@s7.ru"]
      return emails.includes(email)
    },
    async downloadReport() {
      try {
        this.isDownload = true;

        const startDateWithOneDayAdded = new Date(this.startDate);
        startDateWithOneDayAdded.setDate(
          startDateWithOneDayAdded.getDate() + 1
        );

        const endDateWithOneDayAdded = new Date(this.endDate);
        endDateWithOneDayAdded.setDate(endDateWithOneDayAdded.getDate() + 1);

        const requestData = {
          companyCode: this.selectedCompany,
          start: startDateWithOneDayAdded,
          end: endDateWithOneDayAdded,
        };

        let resp = await this.$axios.get(
          this.$enums.Endpoints.Other.SaleRegister,
          {
            params: requestData,
            responseType: "arraybuffer",
          }
        );
        this.isDownload = false;
        downloadFile(resp.data, {
          openInNewTab: false,
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          name: "Реестр продаж.xlsx",
        });
      } catch (error) {
        console.error("Ошибка при загрузке отчёта:", error);
      }
    },
    async getCompanies() {
      try {
        let resp = await this.$axios.get(this.$enums.Endpoints.Data.Companies);
        if (this.isS7AdminEmail(this.$store.getters.user.email)) {
          this.companies.push(resp.data[6])
          return;
        }
        this.companies = resp.data;
      } catch (e) {
        console.error("Ошибка при загрузке списка компаний:", e);
      }
    },
  },
};
</script>
