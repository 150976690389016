<template>
    <v-row>
        <v-col col="12" md="5" class="text-color">
            <v-select outlined label="Наименование услуги" :items="variations" v-model="accomodation.name">
            </v-select>
        </v-col>
        <v-col col="12" md="5" class="text-color">
            <v-text-field outlined label="Стоимость (Без НДС)" v-model="accomodation.priceWithoutNDS"
                :rules="[(val) => val > 0 || 'Введите число, например 10.50']">
            </v-text-field>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: {
        accomodation: Object,
        variations: Array,
    },
}
</script>