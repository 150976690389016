<template>
  <feed-back-data-table :feed-back-loading="feedBackLoading" :sms-data="smsData" :total-rows="totalRows"
                        @update:options="handleOptionsUpdate"></feed-back-data-table>

</template>


<script>
import FeedBackDataTable from "@/components/Feedback/FeedBackDataTable.vue";
import axios from "axios";
import {format, parseISO} from "date-fns";

export default {
  components: {FeedBackDataTable},
  data() {
    return {
      smsData: [],
      totalRows: 0,
      options: {
        page: 1,
        itemsPerPage: 5,
      },
      feedBackLoading: false,
    };
  },
  async mounted() {
    await this.GetSms(this.options.page, this.options.itemsPerPage);
  },
  watch: {
    'options.page': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.GetSms(this.options.page, this.options.itemsPerPage);
      }
    },
    'options.itemsPerPage': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.GetSms(this.options.page, this.options.itemsPerPage);
      }
    },
  },
  methods: {
    async GetSms(page, itemsPerPage) {
      let url = `${this.$enums.Endpoints.Sms.GetSms}?page=${page - 1}&take=${itemsPerPage}`;
      this.feedBackLoading = true
      try {
        let response = await axios.get(url);
        this.totalRows = response.data.total;
        this.smsData = response.data.pageItems.map(e => ({
          ...e,
          sentAt: format(parseISO(e.sentAt), 'HH:mm dd.MM.yyyy'),
          receivedAt: format(parseISO(e.receivedAt), 'HH:mm dd.MM.yyyy')
        }));
      } catch (error) {
        console.error("Failed to load SMS data:", error);
      } finally {
        this.feedBackLoading = false
      }
    },
    handleOptionsUpdate(newOptions) {
      this.options = newOptions;
      this.GetSms(this.options.page, this.options.itemsPerPage);
    }
  },
};
</script>

<style scoped>
</style>
